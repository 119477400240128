<template>
  <v-bottom-sheet v-model="sheet" class="text-center">
    <v-sheet
      min-height="200px"
    >
      <v-container>
        <v-row
          justify="center"
        >
          <v-col
            class="text-center"
          >
            <v-btn
              class="mt-6"
              outlined
              @click="acceptCookie"
            >
              Accept All Cookies
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          justify="center"
        >
          <v-col
            cols="8"
            class="text-center"
          >
            Flick Club uses cookies to improve site functionality, provide you with a better browsing experience.
            Detailed information on the use of cookies on this Site, and how you can decline them, is provided in our
            Cookie preferences. By using this Site or clicking on “ACCEPT ALL COOKIES”, you consent to the use of cookies.
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: "Cookie",
  data: () => ({
    sheet: false,
  }),
  mounted () {
    const isCookieAccepted = localStorage.getItem("isCookieAccepted");
    if (isCookieAccepted === null) {
      this.sheet = true;
    }
  },
  methods: {
    acceptCookie () {
      localStorage.setItem("isCookieAccepted", true);
      this.sheet = false;
    },
  },
};
</script>
